export const servicesData = [
    {
        price: 8,
        time: "30 minutos",
        serviceName: "Paseo sanitario",
        serviceDetails: [
            "individual (un solo perro a la vez, excepto perros que vivan juntos)",
            "alrededor del hogar del perro",
            "recogida y entrega a domicilio",
            "incluye: pasear, olfatear y hacer las necesidades",
            "foto/video de cada salida",
            "obligatorio: evaluación previa gratuita"
        ],
        serviceImage: "https://res.cloudinary.com/dovuoehfu/image/upload/v1706450213/flor_pasea/Servicios/Cala_copia_vwmxmb.jpg",
        idName: "paseo-sanitario",
        disabled: false,
    },
    {
        price: 12,
        time: "60 minutos",
        serviceName: "Paseo urbano",
        serviceDetails: [
            "individual (un solo perro a la vez, excepto perros que vivan juntos)",
            "alrededor del hogar del perro",
            "recogida y entrega a domicilio",
            `incluye (según las necesidades del perro):
            ejercicio físico y mental, momentos de relajación, 
            juego, tiempo para olfatear y hacer las necesidades`,
            "foto/video de cada salida",
            "obligatorio: evaluación previa gratuita"
        ],
        serviceImage: "https://res.cloudinary.com/dovuoehfu/image/upload/v1706454640/flor_pasea/Servicios/urbano_copia_jynzdl.jpg",
        idName:"paseo-urbano",
        disabled: false,
        other: {tag: "additional_price", price: 18, time: "90 minutos"}
    },
    {
        price: 28,
        time: "2 horas (+viaje)",
        serviceName: "Excursión en la naturaleza",
        serviceDetails: [
            "individual (un solo perro a la vez, excepto perros que vivan juntos)",
            "en la naturaleza cerca de Segovia",
            "recogida y entrega a domicilio",
            `incluye (según las necesidades del perro):
            ejercicio físico y mental, momentos de relajación, 
            juego, tiempo para olfatear y hacer las necesidades`,
            "foto/video de cada salida",
            "obligatorio: evaluación previa gratuita"
        ],
        serviceImage: "https://res.cloudinary.com/dovuoehfu/image/upload/v1706452680/flor_pasea/Servicios/excursi%C3%B3n_copia_upqyve.jpg",
        idName: "excursion",
        disabled: false,
    },
    {
        price: 20,
        time: "max. 12 horas durante el día",
        serviceName: "Guardería de día",
        serviceDetails: [
            "individual (un solo perro a la vez, excepto perros que vivan juntos)",
            "a 15 min de Segovia, piso con terraza y aire acondicionado",
            `incluye (según las necesidades del perro):
            varios paseos, ejercicio físico, ejercicio mental, 
            jugar, descansar y pasar tiempo de calidad conmigo`,
            "foto/video cada día",
            "opcional: recogida y entrega a domicilio (coste adicional)",
            "obligatorio: evaluación previa gratuita"
        ],
        serviceImage: "https://res.cloudinary.com/dovuoehfu/image/upload/v1706452680/flor_pasea/Servicios/guarder%C3%ADa_copia_tws07j.jpg",
        idName: "guarderia-de-dia",
        disabled: true,
    },
    {
        price: 30,
        time: "noche",
        serviceName: "Guardería de varios días",
        serviceDetails: [
            "individual (un solo perro a la vez, excepto perros que vivan juntos)",
            "a 15 min de Segovia, piso con terraza y aire acondicionado",
            `incluye (según las necesidades del perro):
            varios paseos, ejercicio físico, ejercicio mental, 
            jugar, descansar y pasar tiempo de calidad conmigo`,
            "foto/video cada día",
            "opcional: recogida y entrega a domicilio (coste adicional)",
            "obligatorio: evaluación previa gratuita"
        ],
        serviceImage: "https://res.cloudinary.com/dovuoehfu/image/upload/v1706452680/flor_pasea/Servicios/varios_d%C3%ADas_copia_c5vqlh.jpg",
        idName: "guarderia-de-varios-dias",
        disabled: true,
    },
]