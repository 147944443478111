import "./Services.css"
import { useEffect } from "react"
import PriceBox from "../UIComponents/PriceBox"
import { servicesData } from "./ServicesData"

export default function Services() {

    useEffect(() => {
        const toService = window.location.href.includes("#");

        if (toService) {
            const serviceId = window.location.href.split("#")[1];
            document.getElementById(serviceId).scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
            });    
            return;
        }

        window.scrollTo(0, 0)

      }, [])

    return(
        <>
         <div className="">
            <div className="about-header-title">
                <h1>Servicios y precios</h1>
            </div>
            <div className="price-box-container">
                {
                    servicesData.map((service) => 
                        <PriceBox 
                            price={service.price}
                            time={service.time}
                            serviceName={service.serviceName}
                            serviceDetails={service.serviceDetails}
                            serviceImage={service.serviceImage}
                            idName={service.idName}
                            disabled={service.disabled}
                            other={service.other}
                        />
                    )
                }
            </div>    
            <div className="price-note">
                <p>*Compra un abono de 10 salidas (de paseo sanitario, paseo urbano o excursión en la 
                    naturaleza) por adelantado y obtén un 10% de descuento! El abono es válido 
                    por 2 meses desde la fecha de compra.</p>
                <p>**En caso de perros que vivan juntos, el segundo perro tiene un 50% de descuento.</p>
            </div>
        </div>
        </>
    )
}